import React from 'react'
import styled from 'styled-components';

const IntroSA = () => {
    return (
        <Container>
            <ContainerHeader>Software Applications</ContainerHeader>
            <ContainerP>Efficiency drive productivity, automate your day to day tasks with web and mobile applications.</ContainerP>
        </Container>
    )
}

export default IntroSA

const Container = styled.div`
    margin-top: 2rem;
    height: 30vh;
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem; 
    text-align: center;
`
const ContainerP = styled.p`
    text-align: center;
    font-size: clamp(1rem,3vw, 1.25rem); 
    padding: 0rem 1rem;
    margin-bottom: 2rem;
`
