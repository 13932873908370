import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/softwareApplication/Body'


const SoftwareApplication = () => (
    <Layout>
        <SEO title="Software company in kenya - hospital management system"
            description="Kenya Health Information system development company in kenya. we develop software applications for healthcare including hospital management system, erp"
            keywords="software companies in kenya, software company in kenya, software developers in kenya, software development companies in kenya, mobile apps, software development in kenya, Affordable Web development in Nairobi Kenya, app development in Nairobi Kenya, mobile app development in Nairobi Kenya, hospital management system in kenya, chemist software, pharmaceutical software, electronic health records, telemedicine in Nairobi Kenya, front end developer, back end developer, affordable software development in Nairobi Kenya, top software development in Nairobi Kenya, erp"
            img="https://www.tripleaimsoftware.com/static/d053aab2193bc9341c0f904032f0a11c/ee604/HeroSA.png"
            siteUrl="https://www.tripleaimsoftware.com/software-application/">
        </SEO>
        <Body />
    </Layout>
)

export default SoftwareApplication
