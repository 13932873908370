import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const SAprocess = () => {
  const data = useStaticQuery(graphql`
    query SAprocessQuery {
        allSaImgJson {
          edges {
            node {
              alts
              imgs {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
      
    `)


  const getImage = (data) => {
    const imageArray = []
    data.allSaImgJson.edges.forEach((element, index) => {
      imageArray.push(
        <div key={index}>
          <Image
            alt={element.node.alts}
            fluid={element.node.imgs.childImageSharp.fluid}>
          </Image>
        </div>
      )
    })
    return imageArray
  }

  return (
    <Container>
      <ContainerHeader>How we do it</ContainerHeader>
      <ContainerP>Client is involved in every step of the process</ContainerP>
      <ContainerImg data-sal="zoom-in" data-sal-easing="ease">{getImage(data)}</ContainerImg>
    </Container>
  )
}

export default SAprocess

const Container = styled.div`
  height: 100%;
  min-height: 60vh;
  padding: 1rem 1rem;
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
    text-align: center; 
`
const ContainerP = styled.p`
    text-align: center;
    font-size: clamp(1rem,2vw, 1.25rem); 
    margin-bottom: 2rem;  
    align-self: center;
    width: 100%; 
`
const ContainerImg = styled.div``
const Image = styled(Img)``

